<template>
    <div class="loaders-container">
      <div class="loader-wrapper">
        <div class="pulse-loader"></div>
      </div>
    </div>
  </template>
  
  <script setup>

  </script>
  
  <style scoped>
  .loaders-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
  }
  
  .loader-wrapper {
    padding: 20px;
  }
  
  .pulse-loader {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #1eb386;
    position: relative;
    animation: pulse 1.5s ease-in-out infinite;
  }
  
  .pulse-loader::before,
  .pulse-loader::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    border: 2px solid #1eb386;
    animation: ripple 1.5s ease-in-out infinite;
  }
  
  .pulse-loader::after {
    animation-delay: 0.5s;
  }
  
  @keyframes pulse {
    0% { transform: scale(0.95); }
    50% { transform: scale(1.05); }
    100% { transform: scale(0.95); }
  }
  
  @keyframes ripple {
    0% { 
      transform: scale(1);
      opacity: 1;
    }
    100% { 
      transform: scale(1.5);
      opacity: 0;
    }
  }
  
  </style>