<template>
  <div class="image-list-container">
    <div class="search-container">
      <input 
        type="text" 
        v-model="searchQuery" 
        placeholder="Search email..."
        class="search-input"
        @input="handleSearch"
      />
    </div>
    <div v-if="searchQuery && !filteredData.length" class="no-results">
      No Results found for "{{ searchQuery }}"
    </div>
    <div 
      v-for="(item) in displayedData" 
      :key="item._id" 
      class="user-section"
    >
      <div class="header-display">Email: {{ item._id }}</div>
      <div class="email-display">Date: {{ item.updatedAt }}</div>
      <div v-for="resume in item.resumes" :key="resume.originalFileName">
        <details class="pointer job-details-wrapper">
          <summary><strong>Job description</strong></summary>
          <div class="job-details">Job Details: {{ resume.jobDetails }}</div>
        </details>
        <div class="image-pair">
          <div class="image-column">
            <div class="image-wrapper">
              <img 
                loading="lazy"
                :src="`/users-resumes/${item._id}/${resume.originalFileName}`"
                :alt="resume.originalFileName"
                @load="onImageLoad"
              />
            </div>
          </div>
          
          <div class="image-column">
            <div class="image-wrapper">
              <img 
                loading="lazy"
                :src="`/users-resumes/${item._id}/${resume.pdfFileName}`"
                :alt="resume.pdfFileName"
                @load="onImageLoad"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div 
      ref="sentinel"
      class="sentinel"
      v-show="!searchQuery && hasMoreItems"
    ></div>
    <div v-if="isLoading" class="loading-indicator">
      <div class="spinner"></div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, onBeforeUnmount, watch, nextTick } from 'vue'

const props = defineProps({
  data: {
    type: Array,
    required: true
  }
})

const ITEMS_PER_PAGE = 10
const currentPage = ref(0)
const observer = ref(null)
const sentinel = ref(null)
const isLoading = ref(false)
const searchQuery = ref('')
const searchTimeout = ref(null)

const filteredData = computed(() => {
  if (!searchQuery.value) return []
  
  const query = searchQuery.value.toLowerCase()
  return props.data.filter(item => 
    item._id.toLowerCase().includes(query)
  )
})

const displayedData = computed(() => {
  if (searchQuery.value) {
    return filteredData.value
  }
  return paginatedData.value
})

const paginatedData = computed(() => {
  const start = 0
  const end = (currentPage.value + 1) * ITEMS_PER_PAGE
  return props.data.slice(start, end)
})

const hasMoreItems = computed(() => {
  return currentPage.value * ITEMS_PER_PAGE < props.data.length
})

function handleSearch () {
  searchTimeout.value && clearTimeout(searchTimeout.value)
  
  searchTimeout.value = setTimeout(() => {
    searchQuery.value && (currentPage.value = 0)
  }, 300)
}

function onImageLoad (event) {
  const wrapper = event.target.closest('.image-wrapper')
  if (wrapper) {
    wrapper.classList.add('loaded')
  }
}

function loadMore () {
  if (isLoading.value || !hasMoreItems.value || searchQuery.value) return

  isLoading.value = true
  setTimeout(() => {
    currentPage.value++
    isLoading.value = false
  }, 500)
}

function setupObserver () {
  observer.value?.disconnect()

  observer.value = new IntersectionObserver(([entry]) => {
    entry.isIntersecting && hasMoreItems.value && !searchQuery.value && loadMore()
  }, {
    rootMargin: '100px',
    threshold: 0.1
  })

  sentinel.value && observer.value.observe(sentinel.value)
}

watch(paginatedData, () => {
  nextTick(() => {
    setupObserver()
  })
}, { deep: true })

onMounted(() => {
  setupObserver()
})

onBeforeUnmount(() => {
  observer.value?.disconnect()
  searchTimeout.value && clearTimeout(searchTimeout.value)
})
</script>

<style lang="scss">
.image-list-container {
  padding: 20px;
  @media only screen and (min-width: 0) {
    .search-container {
    background: white;
    margin-bottom: 40px;
    display: flex;
    align-items: center;

    .search-input {
      width: 20%;
      padding: 10px 40px 10px 15px;
      border: 2px solid #ddd;
      border-radius: 4px;
      font-size: 16px;
      transition: border-color 0.3s ease;

      &:focus {
        outline: none;
        border-color: #3498db;
      }
    }

    .clear-button {
      position: absolute;
      right: 10px;
      background: none;
      border: none;
      color: #666;
      cursor: pointer;
      padding: 5px;
      
      &:hover {
        color: #333;
      }
    }
  }

  .no-results {
    text-align: center;
    padding: 20px;
    color: #666;
    font-size: 16px;
  }

  .results-counter {
    text-align: center;
    padding: 10px;
    color: #3498db;
    font-size: 14px;
    margin-bottom: 20px;
  }

  .user-section {
    margin-bottom: 40px;
    animation: fadeIn 0.5s ease;

    .header-display {
      font-size: 16px;
      font-weight: bold;
      color: #333;
      margin-bottom: 5px;
      text-align: left;
    }

    .job-details {
      font-size: 14px;
      color: #666;
      margin-bottom: 15px;
      text-align: left;
    }

    .image-pair {
      display: flex;
      gap: 20px;
      margin-bottom: 30px;

      .image-column {
        width: 50%;

        .image-wrapper {
          border: 1px solid #ddd;
          border-radius: 4px;
          overflow: hidden;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          background: #f5f5f5;
          position: relative;

          img {
            width: 100%;
            height: auto;
            display: block;
            opacity: 1;
          }
        }
      }
    }

    .job-details-wrapper {
      margin-bottom: 20px;
    }
  }

  .loading-indicator {
    display: flex;
    justify-content: center;
    padding: 20px;
    
    .spinner {
      width: 40px;
      height: 40px;
      border: 4px solid #f3f3f3;
      border-top: 4px solid #3498db;
      border-radius: 50%;
      animation: spin 1s linear infinite;
    }
  }

  .sentinel {
    height: 20px;
    width: 100%;
  }
  }
  
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}
</style>