<template>
  <div class="reports-view">
      <h1>Reports View</h1>
      <div v-if="loading" >
          <BaseLoader/>
      </div>
      <div v-else>
          <BaseChart
              main-title="Downloads"
              :data="data"
              :dataset-configs="[
                  {
                      field: 'docx_downloaded',
                      label: 'DOCX Downloads',
                      backgroundColor: '#36A2EB',
                      borderColor: '#2693e6'
                  },
                  {
                      field: 'pdf_downloaded',
                      label: 'PDF Downloads',
                      backgroundColor: '#FF6384',
                      borderColor: '#ff4c75'
                  }
              ]"
          />
      </div>
  </div>
</template>

<script setup>
import BaseChart from "@/components/BaseChart.vue";
import { useStore } from "vuex";
import { computed, onMounted } from "vue";
import BaseLoader from "@/components/BaseLoader.vue";

const { state, dispatch } = useStore();

const data = computed(() => state.reports.data);
const loading = computed(() => state.reports.loading);

onMounted(() => {
  dispatch('get_reports');
});
</script>

<style lang="scss" scoped>
</style>