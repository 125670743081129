<template>
    <div class="resumes-preview">
      <h1>Resumes-preview</h1>
      <div v-if="loading" >
          <BaseLoader/>
      </div>
      <div v-else class="resume details">
        <BaseFeedCard  :data="resumeData"/>
      </div>
    </div>
  </template>

  <script setup>
    import {useStore} from "vuex";
    import {computed} from "vue";
    import BaseFeedCard from "@/components/BaseFeedCard.vue";
    import BaseLoader from "@/components/BaseLoader.vue";

    const { state, dispatch, commit } = useStore()
    const resumeData = computed(() => state.resumes.feedResumes)
    const loading = computed(() => state.resumes.loading)

    dispatch('get_resumes_preview')    
  </script>
  