<template>
  <div class="chart-container">
    <div class="chart-header">
      <div class="period-label">
        {{ periodLabel }}
      </div>
      <div class="chart-controls">
        <button class="control-btn" @click="navigate(-1)">
          <span>&lt;</span>
        </button>
        <button class="period-btn" @click="togglePeriod">
          {{ currentPeriod === "week" ? "Week" : "Month" }}
        </button>
        <button class="control-btn" @click="navigate(1)">
          <span>&gt;</span>
        </button>
      </div>
    </div>

    <div class="chart-wrapper">
      <Line :data="chartData" :options="chartOptions" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const currentPeriod = ref("week");
const currentDate = ref(new Date());
const chartData = ref({
  labels: [],
  datasets: [],
});

const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
  datasetConfigs: {
    type: Array,
    required: true,
  },
  mainTitle: {
    type: String,
    required: true,
  },
  dateField: {
    type: String,
    default: "day",
  },
  yAxisStepSize: {
    type: Number,
    default: 1,
  },
});

const periodLabel = computed(() => {
  const year = currentDate.value.getFullYear();
  const month = currentDate.value.toLocaleString("en-US", { month: "long" });
  if (currentPeriod.value === "week") {
    return `Weekly ${props.mainTitle} - ${month} ${year}`;
  }
  return `Monthly ${props.mainTitle} - ${month} ${year}`;
});

const chartOptions = computed(() => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: "top",
    },
    title: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          return `${context.dataset.label}: ${context.raw}`;
        },
      },
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: props.yAxisStepSize,
        callback: function (value) {
          return Math.round(value);
        },
      },
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
        maxRotation: 45,
        minRotation: 45,
      },
    },
  },
  elements: {
    line: {
      tension: 0.4, // Adaugă o curbură ușoară liniilor
    },
    point: {
      radius: 4,
      hitRadius: 10,
      hoverRadius: 6,
    },
  },
}));

function formatDate(date) {
  if (currentPeriod.value === "week") {
    return new Date(date).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
  } else {
    return new Date(date).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
  }
}

function getDaysInMonth(year, month) {
  return new Date(year, month + 1, 0).getDate();
}

function generateChartData(startDate, endDate, data) {
  let current = new Date(startDate);
  const labels = [];
  const datasets = props.datasetConfigs.map((config) => ({
    label: config.label,
    data: [],
    backgroundColor: config.backgroundColor,
    borderColor: config.borderColor,
    borderWidth: 2,
    fill: false,
    pointBackgroundColor: config.backgroundColor,
    pointBorderColor: config.borderColor,
  }));

  while (current <= endDate) {
    const currentDateStr = current.toISOString().split("T")[0];
    const dayData = data.find((d) => d[props.dateField] === currentDateStr);
    labels.push(formatDate(current));
    props.datasetConfigs.forEach((config, index) => {
      datasets[index].data.push(dayData ? dayData[config.field] || 0 : 0);
    });

    current.setDate(current.getDate() + 1);
  }
  return {
    labels,
    datasets,
  };
}

function calculatePeriod() {
  const end = new Date(currentDate.value);
  const start = new Date(currentDate.value);
  if (currentPeriod.value === "week") {
    start.setDate(start.getDate() - 6);
  } else {
    start.setDate(1);
    const lastDay = getDaysInMonth(end.getFullYear(), end.getMonth());
    end.setDate(lastDay);
  }
  chartData.value = generateChartData(start, end, props.data);
}

function navigate(direction) {
  if (currentPeriod.value === "week") {
    currentDate.value.setDate(currentDate.value.getDate() + direction * 7);
  } else {
    currentDate.value.setMonth(currentDate.value.getMonth() + direction);
  }
  currentDate.value = new Date(currentDate.value);
  calculatePeriod();
}

function togglePeriod() {
  currentPeriod.value = currentPeriod.value === "week" ? "month" : "week";
  calculatePeriod();
}

onMounted(() => {
  calculatePeriod();
});
</script>

<style scoped lang="scss">
@media only screen and (min-width: 0) {
  .chart-container {
    background: white;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    .chart-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      .period-label {
        font-size: 16px;
        font-weight: 500;
        color: #333;
      }
      .chart-controls {
        display: flex;
        gap: 8px;
        align-items: center;
        .control-btn,
        .period-btn {
          border: none;
          background: transparent;
          cursor: pointer;
          padding: 4px 8px;
          border-radius: 4px;
          color: #666;
          font-size: 14px;
          transition: all 0.2s;
          &:hover {
            background: #e0e0e0;
            color: #333;
          }
        }
        .control-btn {
          padding: 4px 8px;
        }
        .period-btn {
          background: #f0f0f0;
          padding: 4px 12px;
        }
      }
    }
    .chart-wrapper {
      height: 300px;
      position: relative;
    }
  }
}
</style>