import axios from "axios"
import {api} from "./api"

export const state = {
    data: [],
    loading: false
  
}

export const mutations = {
  SET_REPORTS (state, {key, data}) {
    state[key] = data
  },
  SET_LOADING(state, value) {
    state.loading = value
}
}

export const actions = {
  async get_reports ({commit}) {
    try {
      commit('SET_LOADING', true)
      const url = `${api.reports}`
      const { data } = await axios.get(url)
      commit('SET_REPORTS', {key: 'data', data})  
      commit('SET_LOADING', false)
    } catch (err) {
      commit('SET_LOADING', false)
      console.log(err)
    }
  }
}